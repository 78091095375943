/* Location Info styling */
.location-info {
    position: fixed;
    top: 20px;
    right: 20px;
    /* Removed box styling: background, padding, border, shadow */
    color: #bdc3c7; /* Text color for visibility */
    font-size: 14px;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
    z-index: 1000; /* Ensure it stays on top */
    opacity: 1; /* Full opacity since no background */
    transition: opacity 0.3s ease;
    font-family: Arial, sans-serif; /* Choose a clean font */
  }
  
  .location-info:hover {
    opacity: 0.8; /* Slightly reduce opacity on hover for subtle effect */
  }
  
  .location-info-title {
    font-weight: bold;
    margin-right: 5px;
  }
  
  .location-info-description {
    /* No additional styling needed for plain text */
  }
  
  /* Responsive Design */
  @media only screen and (max-width: 768px) {
    .location-info {
      top: 18px;
      right: 10px;
      font-size: 12px;
      max-width: 200px;
    }
  
    .location-info-title {
      font-size: 12px;
    }
  
    .location-info-description {
      font-size: 12px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .location-info {
      top: 18px;
      right: 5px;
      font-size: 11px;
      max-width: 180px;
    }
  
    .location-info-title {
      font-size: 11px;
    }
  
    .location-info-description {
      font-size: 11px;
    }
  }
  