/* src/App.css */
.App {
  display: flex;
  height: 100vh;
  margin: 0;
  overflow: hidden;
}

.left {
  width: 30%;
  background-color: #f0f0f0; /* Optional: for visual separation */
  padding: 20px;
  box-sizing: border-box;
}

.right {
  width: 100%;
}

canvas {
  width: 100%;
  height: 100%;
}
