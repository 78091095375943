/* Gallery.css */

/* Existing Styles */

.gallery-icon-container {
    position: fixed;
    bottom: 20px;
    left: 97%;
    transform: translateX(-50%);
    z-index: 1000; /* Ensure it stays on top of other elements */
    cursor: pointer;
}

/* Clickable icon styling */
.gallery-icon {
    width: 50px;  /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    opacity: 0.7;
}

/* Media query for screens smaller than 768px (mobile devices) */
@media only screen and (max-width: 768px) {
    /* Clickable icon container */
    .gallery-icon-container {
        left: 90%;
        bottom:10px;
    }
}

/* New Styles for the Popup Gallery */

/* Overlay */
.gallery-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1001; /* Above the icon */
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease;
}

/* Popup Container */
.gallery-popup {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    animation: slideIn 0.3s ease;
}

/* Close Button */
.gallery-popup-close {
    position: absolute;
    top: 10px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
    color: #333;
    transition: color 0.2s;
}

.gallery-popup-close:hover {
    color: #000;
}

/* Gallery Images */
.gallery-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 40px; /* Space below the close button */
}

.gallery-popup-image {
    width: calc(33.333% - 10px);
    height: auto;
    border-radius: 4px;
    object-fit: cover;
    transition: transform 0.2s;
}

.gallery-popup-image:hover {
    transform: scale(1.05);
}

/* Animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideIn {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

/* Responsive Adjustments */
@media only screen and (max-width: 768px) {
    .gallery-popup-image {
        width: calc(50% - 10px);
    }

    .gallery-icon {
        width:35px;
        height:35px;
    }
}

@media only screen and (max-width: 480px) {
    .gallery-popup-image {
        width: 100%;
    }

    .gallery-icon {
        width:35px;
        height:35px;
    }
}
