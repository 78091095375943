/* Container for the popup and buttons */
.popup-info-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000; /* Ensure it stays on top of other elements */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  opacity: 0.9;
}

/* Popup Info styling */
.popup-info {
  width: 300px;
  padding: 15px;
  background-color: #2c3e50;
  color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px; /* Space between popup and buttons */
  display: flex;
  flex-direction: column;
}

.popup-info-title {
  font-size: 18px;
  margin-bottom: 10px;
  color: #ecf0f1;
}

.popup-info-description {
  font-size: 14px;
  color: #bdc3c7;
}

/* Buttons container */
.popup-buttons {
  display: flex;
  gap: 10px; /* Space between buttons */
}

/* Individual button styling */
.popup-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  transition: transform 0.2s;
}

.popup-button:hover {
  transform: scale(1.1);
}

.popup-button img {
  width: 40px; /* Adjust size as needed */
  height: 40px;
  border-radius: 4px;
}

/* Active button can have additional styling if needed */
.popup-button.active img {
  /* Example: Add a border to indicate active state */
  /*border: 2px solid #ecf0f1;*/
}

/* Responsive styles */
@media only screen and (max-width: 768px) {
  .popup-info-container {
      left: 10px;
      bottom: 10px;
  }

  .popup-info {
      width: 200px;
      padding: 10px;
      font-size: 12px;
  }

  .popup-info img {
   height:215px;
   width:200px;
  }

  .popup-info-title {
      font-size: 16px;
  }

  .popup-info-description {
      font-size: 12px;
  }

  .popup-buttons {
      gap: 5px;
  }

  .popup-button img {
      width: 30px;
      height: 30px;
  }
}

@media only screen and (max-width: 850px) and (orientation: landscape) {
  .popup-info-container {
      left: 15px;
      bottom: 15px;
  }

  .popup-info {
      width: 85%;
      padding: 12px;
      font-size: 15px;
  }

  .popup-info-title {
      font-size: 20px;
      margin-top: 0px;
  }

  .popup-info-description {
      font-size: 14px;
  }

  .popup-buttons {
      gap: 8px;
  }

  .popup-button img {
      width: 35px;
      height: 35px;
  }
}
