body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  overflow: hidden;
  
}

.left-sidebar{
  display:none
}

.filters-container {
  width: 300px;
  margin: 0 auto;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.filters-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.floor-section {
  margin-bottom: 20px;
}

.floor-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.floor-header img {
  width: 230px;
  height: 60px;
  margin-right: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.filter-options {
  display: flex;
  flex-direction: column;
}

.filter-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 25px;
}

.filter-option img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.filter-option .switch {
  margin-left: 10px;
}

.info-container {
  background-color: #e0e0e0;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  
}

.info-icon {
  background-color: #2196F3;
  color: white;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.info-content{
  margin-top:10px
}

.info-content p {
  margin: 5px 0;
  font-size: 14px;
}

.info-content strong {
  font-weight: bold;
}


.button-fixed-top-right {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  padding: 15px 30px;
  font-size: 18px;
  border-radius: 8px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}
/* Expandable list container positioned in the top-left corner */
.expandable-list {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Styling for each main item */
.item {
  margin-bottom: 10px;
  width: 100%;
  position: relative;
}

.item-button {
  background-color: #2c3e50;
  color: white;
  padding: 15px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  text-align: left;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-button:hover {
  background-color: #34495e;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

/* Icon styling for main items */
.item-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* Expand icon on the right */
.expand-icon {
  font-size: 16px;
  transition: transform 0.3s ease;
}

.expand-icon.expanded {
  transform: rotate(180deg); /* Rotate the icon when expanded */
}

/* Styling for subitems */
.subitems {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-left: 15px; /* Tuck in the subitems */
  width: 85%;
}

.subitem-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subitem {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: #2c3e50;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.subitem:last-child {
  border-bottom: none;
}

.subitem:hover {
  background-color: #ecf0f1;
}

/* Subitem icon styling */
.subitem-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

/* Eye icon positioning */
.eye-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-right: 10px;
}





/*  test  */
/* Container styles */
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 20px;
}


/* Product name at the top-right corner */
.product-name {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  color: #fefefe;
}

/* Main items (top-left corner) */
.main-items {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
}

.main-item {
  margin: 10px 0; /* Increased margin for larger spacing between items */
  padding: 10px 80px; /* Increased padding to make the buttons bigger */
  cursor: pointer;
  border-radius: 40px; /* Larger rounded corners */
  text-align: center;
  font-weight: bold;
  font-size: 23px; /* Increased font size */
  transition: all 0.3s ease;
  color: white;
  opacity: 0.5; /* Default opacity for non-selected items */
  background-color: #8a939b; /* Light gray for non-selected items */
}

.main-item.selected {
  opacity: 1; /* Full opacity for selected item */
  background-color: #2b3a45; /* Dark background color for selected item */
}

.main-item.selected:hover {
  opacity: 1;
}

.main-item:hover {
  opacity: 0.7; /* Slight opacity increase on hover for non-selected items */
}

/* Textures (bottom center) */
.textures {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.texture {
  text-align: center;
}

.texture img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  border: 2px solid #ccc;
  transition: border-color 0.3s;
}

.texture img:hover {
  border-color: #007bff;
}

.texture.highlighted img {
  border-color: #ff4500;
}

.texture p {
  margin-top: 5px;
  font-size: 20px;
  color:white;
}

/* Bottom-right icon */
.bottom-right-icon {
  position: absolute;
  bottom: 20px;
  right: 20px;
  
  height: 40px;
  width: 128px;
}



.measurment-btn{
  position: absolute;
  top: 15px;
  left: 330px;
  padding: 10px;
  font-size: 16px;
  z-Index: 1;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.measurment-icon{
  width: 40px;
  height: 40px;
}

.measurment-unit{
  position: absolute;
  top: 22px;
  left: 240px;
  padding: 10px;
  font-size: 16px;
  z-index: 1;
  border-radius: 5px;
  cursor: pointer;
}
/* end test  */




/* Media query for screens smaller than 768px (mobile devices) */
@media only screen and (max-width: 768px) {

  /* Adjust expandable list for mobile */
  .expandable-list {
    width: 150px; /* Smaller width for mobile */
    top: 10px;
    left: 10px;
  }

  /* Smaller item buttons for mobile */
  .item-button {
    padding: 10px; /* Reduce padding */
    font-size: 14px; /* Smaller font size */
  }

  /* Adjust subitems for mobile view */
  .subitems {
    margin-left: 5px; /* Reduce margin */
  }

  .subitem-container {
    /*justify-content: center; */ /* Center align */
  }

  /* Hide text in subitem-container, only show icons on mobile */
  .subitem {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px; /* Smaller padding */
    font-size:12px;
  }

  .subitem span, /* Add this to hide any text inside subitems */
  .subitem a {
    display: none; /* Hide text in mobile */
  }

  .subitem:hover {
    background-color: none; /* Remove hover effect for mobile */
  }

  .subitem-icon {
    margin-right: 5px; /* Remove margin for mobile */
  }

/* PRODUCT VIEW MOBILE */
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 20px;
}


/* Product name at the top-right corner */
.product-name {
  position: absolute;
  top: 18px;
  right: 20px;
  font-size: 18px;
  color: #fefefe;
}

/* Main items (top-left corner) */
.main-items {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
}

.main-item {
  margin: 5px 0; /* Increased margin for larger spacing between items */
  padding: 10px 30px; /* Increased padding to make the buttons bigger */
  cursor: pointer;
  border-radius: 40px; /* Larger rounded corners */
  text-align: center;
  font-weight: bold;
  font-size: 23px; /* Increased font size */
  transition: all 0.3s ease;
  color: white;
  opacity: 0.5; /* Default opacity for non-selected items */
  background-color: #8a939b; /* Light gray for non-selected items */
}

.main-item.selected {
  opacity: 1; /* Full opacity for selected item */
  background-color: #2b3a45; /* Dark background color for selected item */
}

.main-item.selected:hover {
  opacity: 1;
}

.main-item:hover {
  opacity: 0.7; /* Slight opacity increase on hover for non-selected items */
}

/* Textures (bottom center) */
.textures {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.texture {
  text-align: center;
}

.texture img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  border: 2px solid #ccc;
  transition: border-color 0.3s;
}

.texture img:hover {
  border-color: #007bff;
}

.texture.highlighted img {
  border-color: #ff4500;
}

.texture p {
  margin-top: 5px;
  font-size: 16px;
  color:white;
  margin-bottom:25px;
}

/* Bottom-right icon */
.bottom-right-icon {
  position: absolute;
  bottom: 20px;
  right: 20px;
  
  height: 30px;
  width: 96px;
}


.measurment-btn{
  position: absolute;
  top: 5px;
  left: 315px;
  padding: 10px;
  font-size: 16px;
  z-Index: 1;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.measurment-icon{
  width: 30px;
  height: 30px;
}

.measurment-btn{
  top: 44px;
  left: auto;
  right:5px;
}

.measurment-unit{
  position: absolute;
  top: 54px;
  left: auto;
  padding: 6px;
  font-size: 12px;
  z-index: 1;
  border-radius: 5px;
  cursor: pointer;
  right:55px;
}


/* END PRODUCT VIEW MOBILE */
 
}




@media only screen and (max-width: 850px) and (orientation: landscape) {

  /* Adjust expandable list for mobile */
  .expandable-list {
    width: 150px; /* Smaller width for mobile */
    top: 10px;
    left: 10px;
  }

  /* Smaller item buttons for mobile */
  .item-button {
    padding: 10px; /* Reduce padding */
    font-size: 14px; /* Smaller font size */
  }

  /* Adjust subitems for mobile view */
  .subitems {
    margin-left: 5px; /* Reduce margin */
  }

  .subitem-container {
    /*justify-content: center; */ /* Center align */
  }

  /* Hide text in subitem-container, only show icons on mobile */
  .subitem {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px; /* Smaller padding */
    font-size:12px;
  }

  .subitem span, /* Add this to hide any text inside subitems */
  .subitem a {
    display: none; /* Hide text in mobile */
  }

  .subitem:hover {
    background-color: none; /* Remove hover effect for mobile */
  }

  .subitem-icon {
    margin-right: 5px; /* Remove margin for mobile */
  }
 
}