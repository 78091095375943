
/* Clickable icon container */
.clickable-icon-container {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000; /* Ensure it stays on top of other elements */
    cursor: pointer;
}

/* Clickable icon styling */
.clickable-icon {
    width: 75px;  /* Adjust size as needed */
    height: 75px; /* Adjust size as needed */
    opacity:0.7;
}
